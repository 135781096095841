(function ($, site, prodcat) {
  var productImageMed = {
    setup: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.init(context);
    },
    init: function (context, data) {
      var $template = $('.js-product__image-link', context);
      var productImageTemplate = site.template.get({
        name: 'product_image_med_v1',
        data: data
      });

      if ($template.length < 1 || !data) {
        return;
      }

      $template.html($(productImageTemplate).html());
    }
  };

  Drupal.behaviors.productImageMedV1 = {
    attached: false,
    attach: function (context) {
      productImageMed.setup(context);
    }
  };

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    var sku = prodcat.data.getSku(parseInt(skuBaseId));
    var data = sku ? sku : prod.defaultSku ? prod.defaultSku : prod;

    productImageMed.init($product, data);
  });
})(jQuery, window.site || {}, window.prodcat || {});
